<template>
    <div class="container mx-auto">
        <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Datos personales</h3>

        <!-- Gov Id and name -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

            <!-- GovId -->
            <div class="flex items-center flex-col">
                <div class="flex flex-row w-full">
                    <label class="block text-gray-700 text-left">Número de cédula</label>
                </div>
                <div class="flex flex-row w-full">
                    <div class="relative flex-grow">
                        <input v-model="form.gov_id" @input="cleanData()" @keyup.enter="doSearchEmployee()"
                            type="number" :class="{ 'border-red-500': !isValidValue(form.gov_id) || !isValidGovId() }"
                            placeholder="Número de cédula" required
                            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                    </div>
                    <button @click="doSearchEmployee()" type="button"
                        :disabled="!isValidValue(form.gov_id) || !isValidGovId() || loadingEmployee"
                        class="ml-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700 focus:outline-none">
                        <div class="flex items-center">
                            <span>Buscar</span>
                            <svg v-if="loadingEmployee" class="animate-spin h-5 w-5 text-white ml-2"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4">
                                </circle>
                                <path class="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                </path>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
            <!-- GovId -->

            <!-- Name -->
            <div class="flex items-center flex-col" v-if="form.employee_id">
                <div class="flex flex-row w-full">
                    <label class="block text-gray-700 text-left">Nombre completo</label>
                </div>
                <div class="flex flex-row w-full">
                    <div class="relative flex-grow">
                        <input v-model="form.name" disabled :class="{ 'border-red-500': !isValidValue(form.name) }"
                            required
                            class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                    </div>
                </div>
            </div>
            <!-- Name -->
        </div>
        <!-- Gov Id and name -->

        <div class="mt-2" v-if="form.employee_id">

            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">

                <!-- Code -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Gafete</label>
                    <input v-model="form.code" :class="{ 'border-red-500': !isValidValue(form.code) }" required disabled
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Code -->

                <!-- Department Code-->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Bravo</label>
                    <input v-model="form.department_code"
                        :class="{ 'border-red-500': !isValidValue(form.department_code) }" required disabled
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Department Code -->

                <!-- Nombre Department-->
                <div class="flex flex-col w-full" style="grid-column: span 2;">
                    <label class="block text-gray-700 text-left">Nombre del Bravo</label>
                    <input v-model="form.department_name"
                        :class="{ 'border-red-500': !isValidValue(form.department_name) }" required disabled
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Department Name -->
            </div>

            <div class="grid grid-cols-2 gap-4 mt-4">

                <!-- Proprietary name -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Nombre de Propietario</label>
                    <input v-model="form.proprietary_name"
                        :class="{ 'border-red-500': !isValidValue(form.proprietary_name) }"
                        placeholder="Nombre de Propietario" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Proprietary name -->


                <!-- Proprietary email -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Correo de Propietario</label>
                    <input v-model="form.proprietary_email"
                        :class="{ 'border-red-500': !isValidValue(form.proprietary_email) }"
                        placeholder="Correo de Propietario" type="email" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Proprietary email -->
            </div>

            <!-- Proprietary Gov ID -->
            <div class="flex flex-col w-full mt-4">
                <label class="block text-gray-700 text-left">Cédula del Propietario</label>
                <input v-model="form.proprietary_gov_id" :class="{ 'border-red-500': !isValidValue(form.proprietary_gov_id) }" 
                    required class="w-1/2 px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
            </div>
            <!-- End Proprietary Gov ID -->

            <div class="grid grid-cols-2 gap-4 mt-4">

                <!--  Vehicle Type -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Tipo de Vehículo</label>
                    <select v-model="form.vehicle_type" :class="{ 'border-red-500': !isValidValue(form.vehicle_type) }"
                        required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
                        <option value="" disabled selected>Selecciona un tipo</option>
                        <option v-for="vehicleType in vehicleTypes" :key="vehicleType.id" :value="vehicleType.id">{{
                            vehicleType.name }}</option>
                    </select>
                </div>
                <!-- End Vehicle Type -->

                <!--  Plate -->
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Placa</label>
                    <input v-model="form.plate" :class="{ 'border-red-500': !isValidValue(form.plate) }"
                        placeholder="Placa de vehículo" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
                <!-- End Plate -->

            </div>

            <!-- Alert -->
            <div class="flex flex-col w-full mt-4">
                <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                    <div class="flex">
                        <div class="py-1">
                            <svg class="fill-current h-6 w-6 text-yellow-500 mr-4" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path
                                    d="M10 0c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm-.5-6.5h1v-5h-1v5zm0 2h1v-1h-1v1z" />
                            </svg>
                        </div>
                        <div>
                            <p class="font-bold" style="text-align: left;">Atención:</p>
                            <p class="text-sm">Asegúrate de adjuntar una copia del recibo de pago del marchamo para
                                procesar tu solicitud de forma más eficiente.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Alert -->


            <!-- Attachment -->
            <div class="flex flex-col w-full mt-4">
                <label class="block text-gray-700 text-left">Adjuntar archivo</label>
                <input type="file" placeholder="Adjuntar archivo" @change="onFileChange($event)"
                    class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
            </div>
            <!-- End Attachment-->


            <!-- Detail -->
            <div class="flex flex-col w-full mt-4">
                <label class="block text-gray-700 text-left" for="detail">Detalle</label>
                <textarea v-model="form.detail" id="detail" name="detail"
                    placeholder="Cualquier detalle adicional para el marchamo" rows="3"
                    :class="{ 'border-red-500': !isValidValue(form.detail) }"
                    class="form-textarea w-full px-4 py-3 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"></textarea>
            </div>
            <!-- Detail -->

            <!-- New Fields -->
            <div class="grid grid-cols-2 gap-4 mt-4">
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Correo Electrónico de Factura Digital</label>
                    <input v-model="form.digital_invoice_email" type="email"
                        :class="{ 'border-red-500': !isValidValue(form.digital_invoice_email) }"
                        placeholder="Correo Electrónico de Factura Digital" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>

                <div class="flex items-center w-full">
                    <input type="checkbox" v-model="form.wants_mail_delivery_services"
                        :class="{ 'border-red-500': !isValidValue(form.wants_mail_delivery_services) }"
                        class="rounded-lg border focus:outline-none focus:border-blue-400 mr-2" />
                    <label class="block text-gray-700 text-left">Servicios de Entrega por Correo</label>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mt-4">
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Provincia</label>
                    <select v-model="form.province" :class="{ 'border-red-500': !isValidValue(form.province) }"  @change="onProvinceChange"
                        required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
                        <option value="" disabled selected>Seleccionar una provincia</option>
                        <option v-for="province in provinces" :key="province.id" :value="province.id">{{
                            province.name }}</option>
                    </select>
                </div>

                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Cantón</label>
                    <select v-model="form.canton" :class="{ 'border-red-500': !isValidValue(form.canton) }"  @change="onCantonChange"
                        required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
                        <option value="" disabled selected>Seleccionar un cantón</option>
                        <option v-for="canton in cantons" :key="canton.id" :value="canton.id">{{
                            canton.name }}</option>
                    </select>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mt-4">
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Distrito</label>
                    <select v-model="form.district" :class="{ 'border-red-500': !isValidValue(form.district) }"
                        required class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400">
                        <option value="" disabled selected>Seleccionar un cantón</option>
                        <option v-for="district in districts" :key="district.id" :value="district.id">{{
                            district.name }}</option>
                    </select>
                </div>

                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Dirección Exacta</label>
                    <textarea v-model="form.exact_address" rows="3"
                        :class="{ 'border-red-500': !isValidValue(form.exact_address) }"
                        placeholder="Dirección Exacta" required
                        class="form-textarea w-full px-4 py-3 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"></textarea>
                </div>
            </div>

            <!-- Montos a pagar -->
            <h3 class="text-2xl font-bold mb-2 text-left w-full mt-4">Montos a pagar</h3>

            <div class="grid grid-cols-2 gap-4 mt-4">
                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Nombre del Receptor Autorizado</label>
                    <input v-model="form.authorized_receiver_name"
                        :class="{ 'border-red-500': !isValidValue(form.authorized_receiver_name) }"
                        placeholder="Nombre del Receptor Autorizado" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>

                <div class="flex flex-col w-full">
                    <label class="block text-gray-700 text-left">Teléfono del Receptor Autorizado</label>
                    <input v-model="form.authorized_receiver_phone"
                        :class="{ 'border-red-500': !isValidValue(form.authorized_receiver_phone) }"
                        placeholder="Teléfono del Receptor Autorizado" required
                        class="w-full px-3 py-2 rounded-lg border focus:outline-none focus:border-blue-400" />
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mt-4">
                <div class="flex items-center w-full">
                    <input type="checkbox" v-model="form.self_expanding_insurance"
                        :class="{ 'border-red-500': !isValidValue(form.self_expanding_insurance) }"
                        class="rounded-lg border focus:outline-none focus:border-blue-400 mr-2" />
                    <label class="block text-gray-700 text-left">Seguro de Expansión Propia</label>
                </div>

                <div class="flex items-center w-full">
                    <input type="checkbox" v-model="form.life_insurance"
                        :class="{ 'border-red-500': !isValidValue(form.life_insurance) }"
                        class="rounded-lg border focus:outline-none focus:border-blue-400 mr-2" />
                    <label class="block text-gray-700 text-left">Seguro de Vida</label>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mt-4">
                <div class="flex items-center w-full">
                    <input type="checkbox" v-model="form.civil_liability_insurance"
                        :class="{ 'border-red-500': !isValidValue(form.civil_liability_insurance) }"
                        class="rounded-lg border focus:outline-none focus:border-blue-400 mr-2" />
                    <label class="block text-gray-700 text-left">Seguro de Responsabilidad Civil</label>
                </div>

                <div class="flex items-center w-full">
                    <input type="checkbox" v-model="form.more_protection"
                        :class="{ 'border-red-500': !isValidValue(form.more_protection) }"
                        class="rounded-lg border focus:outline-none focus:border-blue-400 mr-2" />
                    <label class="block text-gray-700 text-left">Más Protección</label>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mt-4">
                <div class="flex items-center w-full">
                    <input type="checkbox" v-model="form.ins_safe_belongings"
                        :class="{ 'border-red-500': !isValidValue(form.ins_safe_belongings) }"
                        class="rounded-lg border focus:outline-none focus:border-blue-400 mr-2" />
                    <label class="block text-gray-700 text-left">Seguro de Pertenencias</label>
                </div>

                <div class="flex items-center w-full">
                    <input type="checkbox" v-model="form.roadside_assistance"
                        :class="{ 'border-red-500': !isValidValue(form.roadside_assistance) }"
                        class="rounded-lg border focus:outline-none focus:border-blue-400 mr-2" />
                    <label class="block text-gray-700 text-left">Asistencia en Carretera</label>
                </div>
            </div>
            <!-- End New Fields -->

            <div class="mt-4 text-left">
                    <button @click="submitForm()" type="button" :disabled="!isValidForm() || loading"
                    class="mt-4 ml-2 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-700 focus:outline-none">
                    <div class="flex items-center">
                        <span>
                            Enviar información
                        </span>
                        <svg v-if="loading" class="animate-spin h-5 w-5 text-white ml-2"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                    </div>
                </button>

            </div>

        </div>
    </div>
</template>

<script>
import axios from "axios";
import { VEHICLE_TYPES } from "../constants"

export default {
    name: "VehicleRegistrationFeeForm",
    data() {
        return {
            vehicleTypes: VEHICLE_TYPES,
            form: {
                gov_id: "",
                employee_id: null,
                department_name: null,
                department_code: null,
                code: "",
                name: "",
                first_name: "",
                last_name: "",
                vehicle_type: "PART",
                plate: "",
                proprietary_name: "",
                proprietary_email: "",
                phone: "",
                detail: "",
                province: "",
                canton: "",
                district: "",
                exact_address: "",
                digital_invoice_email: "",
                wants_mail_delivery_services: false,
                authorized_receiver_name: "",
                authorized_receiver_phone: "",
                self_expanding_insurance: false,
                life_insurance: false,
                civil_liability_insurance: false,
                more_protection: false,
                ins_safe_belongings: false,
                roadside_assistance: false,
            },
            attachment: null,
            loadingEmployee: false,
            loadedEmployee: false,
            provinces: [],
            cantons: [],
            districts: [],
        };
    },
    methods: {
        isValidForm() {
            for (const key in this.form) {
                if (key === "detail") {
                    continue;
                }
                if (!this.isValidValue(this.form[key])) {
                    return false;
                }
            }
            return true;
        },
        onFileChange($event) {
            let file = $event.target.files[0];
            this.attachment = file;
        },
        handleError(error) {
            const errorDetail = error.response ? error.response.data : "Por favor intente más tarde";
            alert("Ha ocurrido un error", errorDetail)
        },
        resetForm() {
            this.form = {
                gov_id: "",
                employee_id: null,
                department_name: null,
                department_code: null,
                code: "",
                name: "",
                first_name: "",
                last_name: "",
                vehicle_type: "PART",
                plate: "",
                phone: "",
                proprietary_name: "",
                proprietary_email: "",
                detail: "",
                province: "",
                canton: "",
                district: "",
                exact_address: "",
                digital_invoice_email: "",
                wants_mail_delivery_services: false,
                authorized_receiver_name: "",
                authorized_receiver_phone: "",
                self_expanding_insurance: false,
                life_insurance: false,
                civil_liability_insurance: false,
                more_protection: false,
                ins_safe_belongings: false,
                roadside_assistance: false,
            }
        },
        isValidValue(value) {
            if (value === null || value === undefined) {
                return false;
            }
            return value !== "";
        },
        isValidGovId() {
            const govId = `${this.form.gov_id}`;
            const amount = govId ? govId.length : 0;
            return amount > 8;
        },
        isValidEmail(email) {
            if (email != "") {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email) ? true : false;
            }
            return false;
        },
        submitForm() {
            this.loading = true;
            const formData = new FormData();
            Object.keys(this.form).forEach((key) => {
                formData.append(key, this.form[key]);
            });
            if (this.attachment) {
                formData.append('attachment', this.attachment, this.attachment.name);
            }
            console.log(formData);
            const currYear = new Date().getFullYear();
            formData.append('year', currYear + 1);
            axios
                .post(`${process.env.VUE_APP_SERVER_ENDPOINT}/vehicleRegistrationFee`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.resetForm();
                    alert("Información guardada exitosamente", "¡Gracias por actualizar sus datos!");
                })
                .catch((err) => {
                    console.log(err.response);
                    const detail = err.response ? err.response.data : "Por favor intente de nuevo";
                    alert(detail);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cleanData() {
            // Clean data logic if needed
        },
        loadEmployeeData(data) {
            this.form.first_name = data.name;
            this.form.last_name = data.lname;
            this.form.sur_name = data.sname;
            this.form.name = `${data.name} ${data.lname} ${data.sname}`;
            this.form.code = data.code;
            this.form.employee_id = data.id;
            this.form.associated = data.association;
            this.form.proprietary_email = data.email;
            this.form.phone = data.phone;
            const { department } = data;
            this.form.department_code = department.code;
            this.form.department_name = department.name;
        },
        doSearchEmployee() {
            this.loadingEmployee = true;
            axios
                .get(
                    `${process.env.VUE_APP_SERVER_ENDPOINT}/employee?govId=${this.form.gov_id}`
                )
                .then((response) => {
                    const data = response.data;
                    if (data) {
                        if (data.association) {
                            this.loadEmployeeData(data);
                        } else {
                            alert("Empleado no asociado", "El empleado no se encuentra asociado a ASO K-9")
                        }
                    } else {
                        console.log('here?')
                        this.noEmployeeLoaded();
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // this.handleError(error);
                })
                .finally(() => {
                    this.loadingEmployee = false;
                });
        },
        onProvinceChange(){
            this.getCanton(this.form.province);
        },
        onCantonChange(){
            this.getDistrict(this.form.canton);
        },
        getProvinces(){
            axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT}/provinces`)
            .then((response) => {
                this.provinces = response.data;
            })
            .catch((error) => {
                this.handleError(error);
            });
        },
        getCanton(provinceId){
            axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT}/cantons?provinceId=${provinceId}`)
            .then((response) => {
                this.cantons = response.data;
            })
            .catch((error) => {
                this.handleError(error);
            });
        },
        getDistrict(cantonId){
            axios.get(`${process.env.VUE_APP_SERVER_ENDPOINT}/districts?cantonId=${cantonId}`)
            .then((response) => {
                this.districts = response.data;
            })
            .catch((error) => {
                this.handleError(error);
            });
        }
    },
    mounted() {
        // Add mounted logic if needed
        this.getProvinces();
    },
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
