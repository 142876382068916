// vehicle_type.js

export const VEHICLE_TYPES = [
  {
    id: 'PART',
    name: "PARTICULAR"
  },
  {
    id: 'MOT',
    name: "MOTO"
  }
];
